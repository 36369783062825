import React from "react";
import { Layout } from "../../../components/Layout";
import { Seo } from "../../../components/Seo";
import { PageHeroWrap } from "../../../components/PageHero";
import { Section, SectionTitle } from "../../../components/Section";
import {
  ServicesBenefitsList,
  ServicesBenefit,
  ServicesBenefitsWrapper,
} from "../../../components/BenefitList";
import { MdxTestimonialsSection } from "../../../components/Testimonials/MdxTestimonials";
import { MdxBlogPostsSection } from "../../../components/Blog/MdxBlogPosts";
import { MdxCaseStudiesSection } from "../../../components/CaseStudies/MdxCaseStudies";

import teamIcon from "../../../images/benefits/team-icon.svg";
import developmentIcon from "../../../images/benefits/development-icon.svg";
import effectiveIcon from "../../../images/benefits/effective-icon.svg";
import transparencyIcon from "../../../images/benefits/transparency-icon.svg";
import productIcon from "../../../images/benefits/product-icon.svg";
import modernTechnologies from "../../../images/benefits/modernTechnologies-icon.svg";
import heroImg from "../../../images/undraw/web-app.svg";
import designImg from "../../../images/howItWorks/requirements.svg";
import discoveryImg from "../../../images/howItWorks/discovery.svg";
import developmentImg from "../../../images/howItWorks/development.svg";
import launchImg from "../../../images/howItWorks/launch.svg";
import supportImg from "../../../images/howItWorks/support.svg";
import controlImg from "../../../images/howItWorks/control.svg";
import ogImage from "./og_product.png";
import { graphql, useStaticQuery } from "gatsby";
import { Image } from "../../../components/Image";
import {
  HeaderContainer,
  Text,
  Title,
} from "../../../components/Services/HeaderContainer";
import { Button } from "../../../components/Button";
import { getContactUrl } from "../../../utils/urls";
import * as styles from "./index.module.scss";
import {
  HowItWorksItem,
  HowItWorksWrapper,
} from "../../../components/HowItWorksItem";

// Deprecated!

const Page: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      cases: allMdx(
        filter: {
          fields: { collection: { eq: "cases" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "app" } }
        }
        sort: { fields: frontmatter___priority }
      ) {
        ...CaseStudiesData
      }

      testimonials: allMdx(
        filter: {
          fields: { collection: { eq: "testimonials" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "app" } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...TestimonialsData
      }

      blogPosts: allMdx(
        filter: {
          fields: { collection: { eq: "blog" } }
          frontmatter: { priority: { gt: 0 }, tags: { in: ["app", "tech"] } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...BlogPostsData
      }
    }
  `);

  return (
    <Layout>
      <Seo
        ogFullTitle
        title="Product Development Service"
        description="Ensure your business stays ahead with custom software solutions."
        ogImage={ogImage}
        maxImagePreview="large"
      />
      <PageHeroWrap className={styles.hero_outer}>
        <HeaderContainer
          title={<Title>Product Development</Title>}
          image={
            <Image
              image={heroImg}
              alt="Product Development"
              className={styles.header_image}
            />
          }
          text={
            <Text>
              Our full-cycle product development service ensures that from
              conception to completion, every stage of your product's journey is
              handled with precision, dedication, and expertise.
              <br /> Stay focused on your business's core activities, knowing
              your product development is in good hands. <br />
              From the initial spark of an idea to its final launch, we're with
              you at every step.
            </Text>
          }
          btn={<Button to={getContactUrl()}>Contact Us</Button>}
        />
      </PageHeroWrap>
      <ServicesBenefitsWrapper>
        <SectionTitle>Benefits</SectionTitle>
        <ServicesBenefitsList>
          <ServicesBenefit
            image={<Image image={developmentIcon} alt="Peace of Mind" />}
            title="Peace of Mind"
          >
            Focus on your primary business activities while we manage the entire
            development lifecycle.
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={productIcon} alt="Product Thinking" />}
            title="Product Thinking"
          >
            Leverage our expertise in product development to discover possible
            improvements early.
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={teamIcon} alt="Dedicated Team" />}
            title="Dedicated Team"
          >
            We will assemble the optimal team for your product based on the
            necessary skills and expertise.
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={effectiveIcon} alt="Effective Development" />}
            title="Effective Development"
          >
            We've been polishing our development processes to increase quality
            while reducing overheads for years.
          </ServicesBenefit>
          <ServicesBenefit
            image={
              <Image image={modernTechnologies} alt="Modern Technologies" />
            }
            title="Modern Technologies"
          >
            Ensures the application is futureproofed while enhancing developers'
            productivity.
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={transparencyIcon} alt="Transparency" />}
            title="Transparency"
          >
            Honesty with our clients, partners, and employees is fundamental to
            creating amazing products.
          </ServicesBenefit>
        </ServicesBenefitsList>
      </ServicesBenefitsWrapper>
      <HowItWorksWrapper>
        <SectionTitle>How it Works</SectionTitle>
        <HowItWorksItem
          title="Discovery"
          image={<Image image={discoveryImg} alt="Discovery" />}
        >
          <p>
            We hold a series of meetings to clarify your needs, problems you
            want to solve, existing solutions, and your ideas on improvements to
            learn as many details about the future product as possible.
          </p>
          <p>
            <strong>Results:</strong> Refined requirements and rough estimates.
          </p>
        </HowItWorksItem>
        <HowItWorksItem
          title="Design"
          image={<Image image={designImg} alt="Design" />}
        >
          <p>
            We work on software architecture, together with deployment strategy
            and testing plans. In parallel, we design the UI/UX of your
            application while thinking through how the final product would work
            in reality. At this stage, we can provide more accurate estimates of
            the whole project.
          </p>
          <p>
            <strong>Results:</strong> Product roadmap, UI/UX designs, Software
            architecture, and accurate estimates.
          </p>
        </HowItWorksItem>
        <HowItWorksItem
          title="Development"
          image={<Image image={developmentImg} alt="Development" />}
        >
          <p>
            We build your application in short cycles, clarifying questions as
            they occur during development. Every iteration ends with a demo
            where you'll see the results of the previous sprint, and we can
            adjust the next sprint based on provided feedback. We also set up a
            staging environment where you can use the latest product version as
            it's being developed.
          </p>
          <p>
            <strong>Results:</strong> Working application with the source code.
          </p>
        </HowItWorksItem>
        <HowItWorksItem
          title="Quality Control"
          image={<Image image={controlImg} alt="Quality Control" />}
        >
          <p>
            Software testing goes in parallel with the development. Developers
            cover critical areas of their code with unit tests, write
            integration, and occasionally automation tests. Every feature gets
            manually tested before it goes live.
          </p>
          <p>
            <strong>Results:</strong> High quality of your product.
          </p>
        </HowItWorksItem>
        <HowItWorksItem
          title="Launch"
          image={<Image image={launchImg} alt="Launch" />}
        >
          <p>
            Once your application is ready, we will set up a production
            environment and deployment mechanism. From this step, all new
            features will go live as soon as they are developed, reviewed, and
            tested.
          </p>
          <p>
            <strong>Results:</strong> The product is ready to use.
          </p>
        </HowItWorksItem>
        <HowItWorksItem
          title="Support"
          image={<Image image={supportImg} alt="Support" />}
        >
          <p>
            Whether that's a minor new feature you need or further full-scale
            development, LeanyLabs is always there to help you. We value our
            good long-lasting relationships above everything else.
          </p>
          <p>
            <strong>Results: </strong> Confidence in the future of the product.
          </p>
        </HowItWorksItem>
      </HowItWorksWrapper>

      <MdxTestimonialsSection testimonialsData={data.testimonials} />
      <MdxCaseStudiesSection caseStudiesData={data.cases} />
      <MdxBlogPostsSection blogPostsData={data.blogPosts} />
    </Layout>
  );
};

export default Page;
